import {Component, OnInit, signal, ViewContainerRef} from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import {LoadingBarModule, LoadingBarService} from '@ngx-loading-bar/core';
import {LoadingBarState} from '@ngx-loading-bar/core/loading-bar.state';
import {ProjectThemeColor, ProjectThemeMode} from './enums/project-theme.enum';
import {RoutingStateService} from './services/routing-state.service';
import {ThemeService} from './services/theme/theme.service';
import {NotificationContainerComponent} from './components/notification-container/notification-container.component';
import {NotificationModule} from '@appkit4/angular-components/notification';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [LoadingBarModule, RouterOutlet, NotificationContainerComponent, NotificationModule]
})
export class AppComponent implements OnInit {
  // TODO: change these to change the theme of the whole project.
  private currentThemeMode: ProjectThemeMode = ProjectThemeMode.LIGHT;
  private currentThemeColor: ProjectThemeColor = ProjectThemeColor.ORANGE;
  private loader: LoadingBarState;
  languageLoaded = signal(false);

  constructor(
    private loadingBar: LoadingBarService,
    private router: Router,
    private routingStateService: RoutingStateService,
    private themeService: ThemeService,
    public viewContainerRef: ViewContainerRef,
    private translateService: TranslateService
  ) {
    this.loader = this.loadingBar.useRef();
    this.themeService.setThemeMode(this.currentThemeMode);
    this.themeService.setThemeColor(this.currentThemeColor);

    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });

    this.routingStateService.loadRouting();
    this.translateService.get('navbar.profilo').subscribe(value => {
      this.languageLoaded.set(true);
    })
  }

  public ngOnInit(): void {
  }

  /**
   * Captures the navigation events and creates a loading bar on top.
   * @param event
   * @private
   */
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loader.start();
    } else if (event instanceof NavigationEnd) {
      this.loader.complete();
    } else if (event instanceof NavigationCancel) {
      this.loader.stop();
    } else if (event instanceof NavigationError) {
      this.loader.stop();
    }
  }

  public obtainBarColor(): string {
    return this.currentThemeColor.toString();
  }
}
