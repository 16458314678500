import { Routes } from '@angular/router';
import { NVCanActivateViaHasRoleGuard } from '@nv/auth';
import {
  buListResolver,
  businessUnitResolver,
  companyListResolver,
  companyResolver, corporateRoleByCompanyResolver,
  corporateRoleListResolver,
  corporateRoleResolver,
  lineOfServiceResolver,
  losListResolver
} from './resolvers/configuration.resolver';
import {
  allProfileResolver,
  profileByRoleUBAResolver,
  profileByRoleUBOResolver,
  profileByRoleUBOUREFResolver,
  profileByRoleUREFResolver,
  profileResolver
} from './resolvers/profile.resolver';
import {
  allContentResolver,
  allElementContentResolver,
  allServiceContentResolver,
  elementContentResolver,
  serviceContentResolver
} from './resolvers/content.resolver';
import { profileNotCompletesGuard } from './guards/profile-not-completes.guard';
import { Role } from './enums/role';
import { elementResolver, serviceResolver } from './resolvers/home.resolver';
import { requestResolver, requestResolverBO } from 'src/app/resolvers/request.resolver';
import { notLoggedGuard } from 'src/app/guards/not-logged.guard';
import { loadProfileGuard } from 'src/app/guards/load-profile.guard';
import { notificationResolver } from 'src/app/resolvers/notification.resolver';

export const APP_ROUTES: Routes = [
  {
    path: '',
    canActivate: [loadProfileGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('src/app/routes/frontoffice/main/main-frontoffice.component'),
        children: [
          {
            path: '',
            pathMatch: 'full',
            canActivate: [profileNotCompletesGuard],
            loadComponent: () => import('src/app/routes/frontoffice/home/home.component')
          },
          {
            path: 'my-profile',
            canActivate: [notLoggedGuard],
            loadComponent: () => import('src/app/routes/frontoffice/my-profile/my-profile.component'),
            resolve: {
              //colleagues: profileByCompanyResolver,
              colleaguesRoles: corporateRoleByCompanyResolver,
              requests: requestResolver
            }
          },
          {
            path: 'notifications',
            canActivate: [notLoggedGuard],
            loadComponent: () => import('src/app/routes/frontoffice/notifications/notifications.component'),
            resolve: {
              data: notificationResolver
            }
          },
          {
            path: 'service/:contentId',
            canActivate: [profileNotCompletesGuard],
            loadComponent: () => import('src/app/routes/frontoffice/content-detail/content-detail.component'),
            resolve: {
              content: serviceResolver
            }
          },
          {
            path: 'element/:contentId',
            canActivate: [profileNotCompletesGuard],
            loadComponent: () => import('src/app/routes/frontoffice/content-detail/content-detail.component'),
            resolve: {
              content: elementResolver
            }
          }
        ]
      },
      {
        path: 'complete-profile',
        canActivate: [notLoggedGuard],
        loadComponent: () => import('src/app/routes/frontoffice/complete-profile/complete-profile.component')
      },
      {
        path: 'login',
        loadComponent: () => import('src/app/routes/frontoffice/access/components/login/login.component')
      },
      {
        path: 'register',
        loadComponent: () => import('src/app/routes/frontoffice/access/components/newUser/newuser.component')
      },
      {
        path: 'backoffice',
        canActivate: [notLoggedGuard],
        loadComponent: () => import('src/app/routes/backoffice/main/main-backoffice.component'),
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'dashboard'
          },
          {
            path: 'dashboard',
            children: [
              {
                path: '',
                loadComponent: () => import('src/app/routes/backoffice/dashboard/dashboard.component'),
                resolve: { losList: losListResolver, buList: buListResolver, compList: companyListResolver }
              }
            ]
          },
          {
            path: 'configuration',
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: '/backoffice/dashboard'
              },
              {
                path: 'company',
                loadComponent: () => import('src/app/routes/backoffice/configuration/company/company.component'),
                resolve: { data: companyResolver }
              },
              {
                path: 'corporate-role',
                loadComponent: () => import('src/app/routes/backoffice/configuration/corporate-role/corporate-role.component'),
                resolve: { data: corporateRoleResolver }
              },
              {
                path: 'line-of-service',
                loadComponent: () => import('src/app/routes/backoffice/configuration/line-of-service/line-of-service.component'),
                resolve: { data: lineOfServiceResolver }
              },
              {
                path: 'business-unit',
                loadComponent: () => import('src/app/routes/backoffice/configuration/business-unit/business-unit.component'),
                resolve: { data: businessUnitResolver, losList: losListResolver }
              }
            ]
          },
          {
            path: 'users',
            children: [
              {
                path: '',
                loadComponent: () => import('src/app/routes/backoffice/users/users.component'),
                resolve: { data: allProfileResolver }
              },
              {
                path: 'new',
                loadComponent: () => import('src/app/routes/backoffice/users/edit-user/edit-user.component'),
                resolve: {
                  losList: losListResolver,
                  buList: buListResolver,
                  companyList: companyListResolver,
                  corporateRoleList: corporateRoleListResolver,
                  internalContactPersonList: profileByRoleUBOResolver,
                  baselineUserPersonList: profileByRoleUBAResolver
                }
              },
              {
                path: ':profileID',
                loadComponent: () => import('src/app/routes/backoffice/users/edit-user/edit-user.component'),
                resolve: {
                  profile: profileResolver,
                  losList: losListResolver,
                  buList: buListResolver,
                  companyList: companyListResolver,
                  corporateRoleList: corporateRoleListResolver,
                  internalContactPersonList: profileByRoleUBOResolver,
                  baselineUserPersonList: profileByRoleUBAResolver
                }
              }
            ]
          },
          {
            path: 'contents',
            children: [
              {
                path: '',
                pathMatch: 'full',
                loadComponent: () => import('src/app/routes/backoffice/contents/contents-management/contents-management.component'),
                resolve: {
                  services: allServiceContentResolver,
                  elements: allElementContentResolver
                }
              },
              {
                path: 'service/new',
                loadComponent: () => import('src/app/routes/backoffice/contents/edit-service/edit-service.component'),
                resolve: {
                  losList: losListResolver,
                  contactPeopleList: profileByRoleUREFResolver
                },
                data: {
                  editEnabled: true
                }
              },
              {
                path: 'service/:serviceID',
                loadComponent: () => import('src/app/routes/backoffice/contents/edit-service/edit-service.component'),
                resolve: {
                  service: serviceContentResolver,
                  losList: losListResolver,
                  contactPeopleList: profileByRoleUREFResolver
                },
                data: {
                  editEnabled: true
                }
              },
              {
                path: 'service/:contentId/preview',
                loadComponent: () => import('src/app/routes/backoffice/contents/preview/preview.component'),
                resolve: { content: serviceResolver },
                children: [
                  {
                    path: '',
                    loadComponent: () => import('src/app/routes/frontoffice/content-detail/content-detail.component'),
                    data: { isPreview: true }
                  }
                ]
              },
              {
                path: 'element/new',
                loadComponent: () => import('src/app/routes/backoffice/contents/edit-element/edit-element.component'),
                resolve: {
                  losList: losListResolver,
                  contactPeopleList: profileByRoleUREFResolver,
                  authorsList: profileByRoleUBOUREFResolver
                },
                data: {
                  editEnabled: true
                }
              },
              {
                path: 'element/:elementID',
                loadComponent: () => import('src/app/routes/backoffice/contents/edit-element/edit-element.component'),
                resolve: {
                  element: elementContentResolver,
                  losList: losListResolver,
                  contactPeopleList: profileByRoleUREFResolver,
                  authorsList: profileByRoleUBOUREFResolver
                },
                data: {
                  editEnabled: true
                }
              },
              {
                path: 'element/:contentId/preview',
                loadComponent: () => import('src/app/routes/backoffice/contents/preview/preview.component'),
                resolve: { content: elementResolver },
                children: [
                  {
                    path: '',
                    loadComponent: () => import('src/app/routes/frontoffice/content-detail/content-detail.component'),
                    data: { isPreview: true }
                  }
                ]
              },
              {
                path: 'monitoring-approvals',
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    loadComponent: () => import('src/app/routes/backoffice/contents/monitoring-approvals/monitoring-approvals.component'),
                    resolve: {
                      data: allContentResolver
                    }
                  },
                  {
                    path: 'service/:serviceID',
                    canActivate: [NVCanActivateViaHasRoleGuard],
                    loadComponent: () => import('src/app/routes/backoffice/contents/edit-service/edit-service.component'),
                    resolve: {
                      service: serviceContentResolver,
                      losList: losListResolver,
                      contactPeopleList: profileByRoleUREFResolver
                    },
                    data: {
                      auth: { hasAnyRoles: [Role.U_REF] },
                      editMode: false
                    }
                  },
                  {
                    path: 'element/:elementID',
                    canActivate: [NVCanActivateViaHasRoleGuard],
                    loadComponent: () => import('src/app/routes/backoffice/contents/edit-element/edit-element.component'),
                    resolve: {
                      element: elementContentResolver,
                      losList: losListResolver,
                      contactPeopleList: profileByRoleUREFResolver,
                      authorsList: profileByRoleUBOUREFResolver
                    },
                    data: {
                      auth: { hasAnyRoles: [Role.U_REF] },
                      editMode: false
                    }
                  }
                ]
              }
            ]
          },
          {
            path: 'requests',
            children: [
              {
                path: '',
                loadComponent: () => import('src/app/routes/backoffice/requests/requests.component'),
                resolve: {
                  data: requestResolverBO,
                  contactPeopleList: profileByRoleUREFResolver
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  }
];
