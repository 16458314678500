<!-- TODO: make the color managed by the CSS file -->
<ngx-loading-bar [color]="obtainBarColor()" [includeBar]="true" [includeSpinner]="false"
                 class="floating-loading-bar"/>
@if (languageLoaded()) {
  <router-outlet/>
} @else {
  <div class="d-flex align-items-center justify-content-center loader-wrapper">
    <span class="loader"></span>
  </div>
}
